#GeneralLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
}

#GeneralLoader hr {
    border: 0;
    margin: 0;
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    animation: custom-spin 2s ease infinite
}

#GeneralLoader :first-child {
    background: #19A68C;
    animation-delay: -1.5s
}

#GeneralLoader :nth-child(2) {
    background: #D71E0E;
    animation-delay: -1s
}

#GeneralLoader :nth-child(3) {
    background: #FDA543;
    animation-delay: -0.5s
}

#GeneralLoader :last-child {
    background: #193B48
}

@keyframes custom-spin {
    0%, 100% {
        transform: translate(0)
    }

    25% {
        transform: translate(160%)
    }

    50% {
        transform: translate(160%, 160%)
    }

    75% {
        transform: translate(0, 160%)
    }
}